<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center mb-1">
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Sort</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-100"
        />

        <download-excel
          :data="exportItems"
          :fields="exportFields"
          :filename="'Employee'"
          :sheetname="'Employee'"
          class="cursor-pointer ml-1"
        >
          <b-button variant="" size="sm" class="d-flex mr-1 export-btn">
            <feather-icon icon="FileIcon" size="14" class="text-success" />
            <label style="font-size: 14px; margin-left: 5px; cursor: pointer">
              Export
            </label>
          </b-button>
        </download-excel>
      </div>
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Search</label>
        <b-form-input
          id="searchInput"
          v-model="filter"
          size="sm"
          type="search"
          placeholder="Search"
        />
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(profile_photo_url)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(actions)="data">
            <div class="text-center">
              <b-link
                :to="{
                  name: 'performance/goal/review',
                  params: {
                    id: data.value.user.id,
                    type: 'goals_setting',
                    isMeeting: data.value.status == 'new' ? false : true,
                  },
                }"
                v-if="getRole() == 'director'"
              >
                <feather-icon
                  icon="EditIcon"
                  size="20"
                  class="text-primary"
                ></feather-icon>
              </b-link>
              <b-link
                :to="{
                  name: 'performance/goal',
                  params: { id: data.value, type: 'goals_setting_send_back' },
                }"
                v-else
              >
                <feather-icon
                  icon="EditIcon"
                  size="20"
                  class="text-primary"
                ></feather-icon>
              </b-link>
            </div>
          </template>

          <template #cell(status)="data">
            <!-- <div class="text-center"> -->
            <b-badge
              :variant="
                data.value == 'new'
                  ? 'primary'
                  : data.value == 'send_back'
                  ? 'info'
                  : data.value == 'approved_meeting'
                  ? 'warning'
                  : 'success'
              "
              pill
              class="text-capitalize"
            >
              {{
                data.value == "new"
                  ? "New"
                  : data.value == "send_back"
                  ? "Send Back"
                  : data.value = "approved_meeting"
                  ? "Approved Meeting"
                  : "Approved"
              }}
            </b-badge>
            <!-- </div> -->
          </template>

          <template #empty>
            <div class="text-center my-3">No data available.</div>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            Showing
            {{ perPage > totalRows ? totalRows : perPage }} of
            {{ totalRows }} entries
          </span>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<style>
table > thead > tr > th {
  white-space: nowrap;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
export default {
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        // {
        //   key: "profile_photo_url",
        //   label: "Avatar",
        // },
        {
          key: "user_id",
          label: "Employee",
        },
        // { key: "strategic_goals", label: "Goals" },
        // { key: "key_performance_indicator", label: "Description" },
        // { key: "weight", label: "Weight" },
        // { key: "target", label: "Target" },
        { key: "due_date", label: "Due Date", sortable: "true" },
        {
          key: "status",
          label: "Status",
          sortable: "true",
        },
        { key: "actions", label: "Actions" },
      ],
      items: [],

      // export excel
      exportFields: {
        Title: "title",
        Employee: "employee",
        Description: "description",
        "Dua Date": "due_date",
        Weight: "weight",
        Target: "target",
      },
      exportItems: [],
      isMeeting: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    // fetch data
    this.fetchData();
  },
  methods: {
    fetchData() {
      let params = {};

      if (this.getRole() == "manager") {
        params = {
          user_id: this.hashid(
            JSON.parse(localStorage.getItem("sw_auth_data")).id
          ),
        };
      } else if (this.getRole() == "director") {
        params = {};
      } else {
        params = {
          user_id: this.hashid(
            JSON.parse(localStorage.getItem("sw_auth_data")).id
          ),
        };
      }

      this.callApi({
        method: "GET",
        url: "goals/fetch",
        params: params,
        success: (response) => {
          response.result.forEach((item) => {
            this.items.push({
              user_id: item.user.name,
              strategic_goals: item.strategic_goals,
              key_performance_indicator: item.key_performance_indicator,
              weight: item.weight,
              target: item.target,
              due_date: item.due_date,
              status: item.status,
              actions: item,
            });
          });
          //
          this.totalRows = this.items.length;

          // export excel
          // this.exportItems = response.result;
        },
        error: (error) => {},
      });

      this.callApi({
        url: "goals/fetch",
        method: "GET",
        params: {
          export: 1,
        },
        success: (res) => {
          res.result.map((item) => {
            this.exportItems.push({
              title: item.strategic_goals,
              employee: item.user.name,
              description: item.key_performance_indicator,
              weight: item.weight,
              target: item.target,
              due_date: item.due_date,
            });
          });
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
